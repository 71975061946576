// src/App.jsx
import AppRoutes from './routes/AppRoutes';
import { AppProvider } from './contexts/AppContext';

const App = () => (
  <AppProvider>
    <AppRoutes />
  </AppProvider>
);

export default App;
