

const MainLayout = ({ children }) => (
    <div className="relative h-screen w-screen">
      {children}
    </div>
  );
  

export default MainLayout;
