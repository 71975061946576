// src/routes/AppRoutes.jsx
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { lazy, Suspense } from 'react';
import MainLayout from '../layouts/MainLayout';

// Lazy loading pentru pagini
const Home = lazy(() => import('../pages/Home'));
const Restaurant = lazy(() => import('../pages/Restaurant'));
const NotFound = lazy(() => import('../pages/NotFound'));

const AppRoutes = () => (
  <Router>
    <MainLayout>
      <Suspense fallback={<div>Se încarcă...</div>}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/restaurant" element={<Restaurant />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </MainLayout>
  </Router>
);

export default AppRoutes;
