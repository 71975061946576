// src/contexts/AppContext.jsx
import { createContext, useState, useContext, useEffect } from 'react';

// Creăm contextul pentru aplicație
const AppContext = createContext();

// Provider pentru mapData și viewportHeight
export const AppProvider = ({ children }) => {
  const [mapData, setMapData] = useState({
    center: [47.0105, 28.8638],
    zoom: 13,
  });

  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <AppContext.Provider value={{ mapData, setMapData, viewportHeight }}>
      {children}
    </AppContext.Provider>
  );
};

// Hook pentru a folosi contextul în componente
export const useAppContext = () => useContext(AppContext);
